export const MAX_VALUE = 255 * 8

export default function generateData(count: number): number[] {
  let delta1 = 0
  let delta2 = 0
  let value = 500
  const result = []
  for (let i = 0; i < count; i++) {
    delta2 = delta2 + Math.random() * 2 - 1
    delta1 = delta1 + delta2
    delta1 = delta1 * 0.85
    delta2 = delta2 * 0.99
    value = value + delta1
    result.push(value << 0)
  }
  return compressValues(result)
}

function sort(values: number[]): number[] {
  return values.sort((a: number, b: number) => (a > b ? 1 : -1))
}

function max(values: number[]): number {
  const sorted = sort(values)
  return sorted[sorted.length - 1]
}

function min(values: number[]): number {
  const sorted = sort(values)
  return sorted[0]
}

function compressValues(values: number[]) {
  const lowest = min(values)
  const translateTo0 = values.map((value) => value - lowest)
  const highest = max(translateTo0)
  const ratio = MAX_VALUE / highest

  return translateTo0.map((value) => (value * ratio) << 0)
}

export function random(from: number, to: number): number {
  const reach = Math.abs(to - from) + 1
  const min = Math.min(from, to)
  return (Math.random() * reach + min) << 0
}
