import { div, mul, Vector2 } from '../utils'
import { Colour, randomColour } from './colour'
import generateData, { MAX_VALUE, random } from './data'
import PixelSetter from './PixelSetter'

// const COLOUR_1: Colour = randomColour(255)
// const COLOUR_2: Colour = randomColour(255)

export default class Sorter {
  canvas: HTMLCanvasElement
  ctx: CanvasRenderingContext2D
  pixels: PixelSetter
  data: number[]
  colours: Colour[]

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      throw new Error('no context')
    }
    this.ctx = ctx
    this.pixels = new PixelSetter(ctx)
    this.data = generateData(1024)
    this.colours = [randomColour(255), randomColour(255)]
  }

  drawSegment(
    pos: Vector2,
    size: Vector2,
    a: Colour,
    b: Colour,
    direction: boolean
  ): void {
    const { pixels } = this
    const xRatio = this.data.length / size[0]
    const yRatio = size[1] / MAX_VALUE

    for (let x = 0; x < size[0]; x++) {
      const index = (x * xRatio) << 0
      const verticalPos = (this.data[index] * yRatio) << 0
      for (let y = 0; y < size[1]; y++) {
        const color: Colour = y < verticalPos ? a : b
        const yy = direction ? y + pos[1] : size[1] - y + pos[1] - 1
        pixels.set(x + pos[0], yy, color)
      }
    }
  }

  draw(): void {
    const { pixels, canvas } = this
    const canvasSize: Vector2 = [canvas.width, canvas.height]
    const segments: Vector2 = [random(1, 4), random(1, 4)]

    const segmentSize = div(canvasSize, segments)
    for (let segmentY = 0; segmentY < segments[1]; segmentY++) {
      const shift = random(0, canvas.width / segments[0] / 2)
      for (let segmentX = 0; segmentX <= segments[0]; segmentX++) {
        const coord: Vector2 = mul([segmentX, segmentY], segmentSize)
        coord[0] -= shift
        if (segmentY % 2 === 0) {
          this.drawSegment(
            coord,
            segmentSize,
            this.colours[0],
            this.colours[1],
            true
          )
        } else {
          this.drawSegment(
            coord,
            segmentSize,
            this.colours[0],
            this.colours[1],
            false
          )
        }
      }
    }

    pixels.flush()
  }
}
