export interface Viewport {
  width: number
  height: number
}

export function viewport(): Viewport {
  const ratio = 1
  if (typeof window === 'undefined') {
    return {
      width: -1,
      height: -1
    }
  }
  const { clientWidth, clientHeight } = global.document.documentElement
  return {
    width: clientWidth * ratio,
    height: clientHeight * ratio
  }
}
