import { random256 } from '../utils'

export type Colour = [number, number, number, number]
export type ColorCorrection = [number, number, number]

export function bias(color: Colour, bias: ColorCorrection): Colour {
  return [
    (color[0] * bias[0]) << 0,
    (color[1] * bias[1]) << 0,
    (color[2] * bias[2]) << 0,
    color[3]
  ]
}

export function randomColour(alpha = random256()): Colour {
  return [random256(), random256(), random256(), alpha]
}

function HUE2RGB(p: number, q: number, t: number): number {
  if (t < 0) t += 1
  if (t > 1) t -= 1
  if (t < 1 / 6) return p + (q - p) * 6 * t
  if (t < 1 / 2) return q
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
  return p
}

export function HSL2RGBA(colour: Colour): Colour {
  const [h, s, l] = colour.map((value) => value / 255)
  let r, g, b

  if (s === 0) {
    r = g = b = l
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    r = HUE2RGB(p, q, h + 1 / 3)
    g = HUE2RGB(p, q, h)
    b = HUE2RGB(p, q, h - 1 / 3)
  }

  return [
    Math.round(r * 255),
    Math.round(g * 255),
    Math.round(b * 255),
    colour[3]
  ]
}
