import { HSL2RGBA, Colour } from './colour'

export default class PixelSetter {
  private ctx: CanvasRenderingContext2D
  private data: ImageData
  private width: number = 0
  private height: number = 0

  constructor(ctx: CanvasRenderingContext2D) {
    this.ctx = ctx
    this.data = this.ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height)
    this.width = ctx.canvas.width
    this.height = ctx.canvas.height
  }

  set(x: number, y: number, color: Colour): void {
    if (x < 0 || y < 0 || x > this.width || y > this.height) return
    const roundedX = x << 0
    const roundedY = y << 0
    const { data, width } = this.data
    const index = (roundedY * width + roundedX) * 4
    const oddA = (roundedX / 2) % 2 === 0 ? 2 : 0.5
    const oddB = (roundedY / 2) % 2 === 0 ? 2 : 0.5
    // const oddC = ((roundedX + roundedY + 1) / 8) % 2 === 0 ? 1.2 : 0.8
    const oddD = (roundedX - 2) % 2 === 0 ? 2 : 0.5

    const HSL: Colour = [
      color[0] * oddA + x / 100,
      color[1] * oddB + oddD / 100,
      color[2], // * oddC + y / 100,
      color[3]
    ]

    const RGB = HSL2RGBA(HSL)
    data[index + 0] = RGB[0]
    data[index + 1] = RGB[1]
    data[index + 2] = RGB[2]
    data[index + 3] = RGB[3]
  }

  flush(): void {
    this.ctx.putImageData(this.data, 0, 0)
  }
}
