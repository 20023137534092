import React, { useEffect, useRef } from 'react'
import './App.css'
import Sorter from './artwork/Sorter'
import { viewport } from './artwork/viewport'
import useInterval from './hooks/useInterval'

function App() {
  const containerElement = useRef<HTMLDivElement>(null)
  const canvas = useRef<HTMLCanvasElement>()
  // const sorter = useRef<Sorter>()

  useEffect(() => {
    if (!containerElement.current) return
    const container = containerElement.current
    const vp = viewport()
    canvas.current = document.createElement('canvas')
    canvas.current.width = vp.width * window.devicePixelRatio
    canvas.current.height = vp.height * window.devicePixelRatio
    canvas.current.style.width = `${vp.width}px`
    canvas.current.style.height = `${vp.height}px`
    container.appendChild(canvas.current)
    draw()
    return () => {
      if (!canvas.current) return
      container.removeChild(canvas.current)
      console.log('Garbage collecting...')
    }
  }, [containerElement])

  function draw() {
    if (!canvas.current) return
    const sorter = new Sorter(canvas.current)
    sorter.draw()
  }

  useInterval(() => draw(), 5000)

  return <div className='App' ref={containerElement} />
}

export default App
